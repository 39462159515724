import classNames from 'classnames';
import { FunctionComponent, useEffect, useState } from 'react';
import { isAddress, getAddress } from '@ethersproject/address';
import { useNetworkConfig } from '../hooks/useWeb3';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestion } from '@fortawesome/pro-regular-svg-icons';

const UNRESOLVED_IMAGES: string[] = [];

export const Token: FunctionComponent<
  React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > & { address: string; overrideImage?: string }
> = ({ address, overrideImage, ...props }) => {
  const { currentNetworkSetings } = useNetworkConfig();

  const [hasError, setHasError] = useState(
    () => !isAddress(address) || UNRESOLVED_IMAGES.includes(address)
  );
  //Nose boogeys
  useEffect(() => {
    if (isAddress(address)) setHasError(false);
  }, [address]);

  return (
    <div
      {...props}
      className={classNames(
        'flex items-center justify-center rounded-full overflow-hidden w-6 h-6 bg-gradient-to-tr from-gray-100 to-gray-50 shadow',
        props.className
      )}
    >
      {(() =>
        hasError || !isAddress(address) ? (
          <div className="flex items-center justify-center w-full h-full text-gray-900 rounded-full">
            <FontAwesomeIcon icon={faQuestion} size="sm" />
          </div>
        ) : (
          <img
            src={
              overrideImage ??
              currentNetworkSetings.tokenImageTemplate.replace(
                '{}',
                getAddress(address)
              )
            }
            alt={getAddress(address)}
            onError={() => {
              UNRESOLVED_IMAGES.push(address);
              setHasError(true);
            }}
          />
        ))()}
    </div>
  );
};

export default Token;
