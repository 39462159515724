import { JsonRpcProvider } from '@ethersproject/providers';
import { NetworkSettings } from '../types/networkTypes';
import Connector from './Connector';

export class MagicLinkConnector extends Connector {
  public provider: JsonRpcProvider;
  public static override connectorName = 'MagicLinkConnector';

  constructor(allowedChains: NetworkSettings[]) {
    super();

    this.provider = new JsonRpcProvider(
      allowedChains[0].rpcUrl,
      allowedChains[0].chainId
    );
  }

  public getConnectorName() {
    return MagicLinkConnector.connectorName;
  }

  public async connect(chainId?: number) {
    return await this.getNetwork();
  }

  public async switchChain(chainId: number) {
    return await this.getNetwork();
  }
}
