import {
  getNetworkSettings,
  NetworksConfig,
} from '@byont-ventures/react-dapp-framework';

export const [bsc] = [
  getNetworkSettings('bsc', 'https://bsc-dataseed.binance.org'),
];

export const networksConfig: NetworksConfig = {
  supportedNetworks: [bsc],
  defaultChainId: bsc.chainId,
};

export const dappConfig = {
  wardenContracts: {
    [bsc.chainId]: '0x6876093fDcfF6510c1E9BdbbC3e44E4d00BAeCB0',
  },
  graphSettings: {
    endpoint: 'https://api.thegraph.com/subgraphs/name/dcip-finance/dcip-bsc',
    chainFilter: {
      // Should be one of https://thegraph.com/docs/en/hosted-service/what-is-hosted-service/#supported-networks-on-the-hosted-service
      [bsc.chainId]: 'bsc',
    },
  },
};
