import { faChevronDown } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Listbox, Transition } from '@headlessui/react';
import classNames from 'classnames';
import { Fragment } from 'react';

export const Select: React.FunctionComponent<{
  value: string;
  values: string[];
  disabled?: boolean;
  onChange?: (newValue: string) => void;
}> = ({ value, values, disabled, onChange }) => {
  return (
    <Listbox
      value={value}
      onChange={onChange ? onChange : () => null}
      disabled={disabled}
    >
      <div className="relative">
        <Listbox.Button
          className={classNames(
            'relative w-full py-2 pl-4 pr-12 text-left transition bg-gray-100 rounded-lg focus:ring-2 ring-offset-2 ring-gray-500 focus:outline-none focus-within:ring-2',
            {
              'hover:bg-gray-200 cursor-pointer': !disabled,
            }
          )}
        >
          <span className="block truncate">{value}</span>

          {!disabled ? (
            <span className="absolute inset-y-0 right-0 flex items-center pr-4 pointer-events-none">
              <FontAwesomeIcon icon={faChevronDown} size="sm" />
            </span>
          ) : null}
        </Listbox.Button>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Listbox.Options className="absolute w-full p-1 mt-1 space-y-1 overflow-auto bg-white border border-gray-200 rounded-lg shadow-lg max-h-60 focus:outline-none">
            {values.map((val, valIdx) => (
              <Listbox.Option
                key={valIdx}
                className={({ active }) =>
                  classNames('cursor-pointer select-none relative rounded-lg', {
                    'bg-gray-100': active,
                  })
                }
                value={val}
              >
                {({ selected }) => (
                  <span
                    className={classNames(
                      'block truncate py-2 px-3 rounded-lg',
                      {
                        'font-medium bg-gray-100': selected,
                      }
                    )}
                  >
                    {val}
                  </span>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  );
};

export default Select;
