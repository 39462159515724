// @ts-check
const path = require('path');

const { loadResource } = require('../../libs/react-dapp-framework/src/locales');

module.exports = {
  i18n: {
    defaultLocale: 'en',
    locales: ['en'],
  },
  localePath: path.resolve('./apps/dcip-auto-investment-manager/locales'),
  resources: {
    ...loadResource('en'),
  },
};
