import { gql } from 'graphql-request';

export const getInvestmentsQuery = gql`
  query getInvestments($chain: String!) {
    investmentEntities(where: { archived: false, chain: $chain }) {
      id
      tokenFrom
      tokenTo
      slippage
      investor
      archived
      chain
      address
    }
  }
`;

export const getStrategiesQuery = gql`
  query getStrategies($investmentId: String!) {
    strategyEntities(
      where: { status_in: ["ACTIVATED", "EXECUTED"], investment: $investmentId }
      orderBy: status
    ) {
      id
      executionRate
      startingRate
      percentage
      status
      type
      amount
    }
  }
`;
