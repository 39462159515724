import { FunctionComponent } from 'react';

import { AccountSelector } from './AccountSelector';
import { NetworkSelector } from './NetworkSelector';

export const AppHeader: FunctionComponent<{ appLogo: JSX.Element }> = ({
  appLogo,
  children,
}) => {
  return (
    <div className="sticky top-0 z-20 w-full py-4 transition-all duration-200 md:h-auto">
      <div className="container flex items-center justify-between mx-auto">
        {appLogo}
        {children}

        <div className="flex items-center space-x-2">
          <NetworkSelector />
          <AccountSelector />
        </div>
      </div>
    </div>
  );
};

export default AppHeader;
