import React from 'react';

export const EthereumIcon: React.FunctionComponent<
  React.SVGProps<SVGSVGElement>
> = ({ ...props }) => (
  <svg
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 119 119"
    {...props}
  >
    <circle cx="59.5" cy="59.5" r="59.5" fill="#627EEA" />
    <g clipPath="url(#clip0)">
      <path
        d="M59.894 16l-.633 2.11v58.034l.633.633 27.012-16.038L59.894 16z"
        fill="#C0CCF7"
      />
      <path d="M59.894 16L32.882 60.739l27.012 16.038V16z" fill="#fff" />
      <path
        d="M59.894 81.842l-.422.422v20.681l.422 1.055 27.012-37.986-27.012 15.828z"
        fill="#C0CCF7"
      />
      <path d="M59.894 104V81.842L32.882 66.014 59.894 104z" fill="#fff" />
      <path
        d="M59.895 76.777l27.011-16.038-27.012-12.24v28.278z"
        fill="#8198EE"
      />
      <path
        d="M32.882 60.739l27.012 16.038V48.499l-27.012 12.24z"
        fill="#C0CCF7"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <path fill="#fff" transform="translate(16 16)" d="M0 0h88v88H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default EthereumIcon;
