export const Input: React.FunctionComponent<
  React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >
> = ({ children, ...props }) => (
  <div className="flex items-center justify-between px-4 space-x-2 transition border border-gray-200 rounded-md group outline-gray-200 focus-within:ring-2 ring-offset-2 ring-gray-500">
    <input
      className="w-full py-2 bg-transparent focus:outline-none placeholder:text-gray-500"
      {...props}
    />
    <div>{children}</div>
  </div>
);

export default Input;
