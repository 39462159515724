/**
 * A safe method to fetch an item from local storage if the window object is present. Will return `null` if there is no window localstorage or window object (i.e., on SSR mode)
 * @param key The unique key to fetch
 * @returns
 */
export const getLsItem = (key: string) =>
  typeof window === 'undefined' ? null : localStorage.getItem(key);

/**
 * A safe method to store an item in local storage if the window object is present. Will silently fail if there is no window localstorage or window object (i.e., on SSR mode)
 * @param key The unique key to store
 * @param value The value to set
 * @returns
 */
export const setLsItem = (key: string, value: string) =>
  typeof window === 'undefined' ? undefined : localStorage.setItem(key, value);

/**
 * A safe method to remove an item from local storage if the window object is present. Will silently fail if there is no window localstorage or window object (i.e., on SSR mode)
 * @param key The unique key to delete
 * @returns
 */
export const removeLsItem = (key: string) =>
  typeof window === 'undefined' ? undefined : localStorage.removeItem(key);
