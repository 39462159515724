import { Fragment, FunctionComponent, useMemo } from 'react';

import { Menu, Transition } from '@headlessui/react';
import Button from './Button';
import classNames from 'classnames';
import { useConnection, useNetworkConfig } from '../hooks/useWeb3';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons';

import { useTranslation } from 'react-i18next';

export const NetworkSelector: FunctionComponent = ({ children }) => {
  const { supportedNetworks } = useNetworkConfig();
  const { desiredNetwork, switchChain } = useConnection();

  const networkSettings = useMemo(
    () =>
      supportedNetworks.find((network) => network.chainId === desiredNetwork),
    [desiredNetwork, supportedNetworks]
  );

  const { t } = useTranslation('react-dapp-framework');

  return (
    <Menu as="div" className="relative inline-block text-left">
      <Menu.Button as="div">
        <Button
          loading={null === desiredNetwork}
          className="space-x-2"
          shadow={true}
        >
          {(() =>
            undefined !== networkSettings ? (
              <>
                <networkSettings.iconComponent className="w-6 h-6" />
                <span className="hidden md:block">
                  {networkSettings.chainName}
                </span>
                <FontAwesomeIcon icon={faChevronDown} size="xs" />
              </>
            ) : null)()}
        </Button>
      </Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2 origin-top-right bg-white border border-gray-100 divide-gray-100 rounded-lg shadow-lg dark:divide-primary-800 dark:border-primary-700 dark:bg-primary-800 focus:outline-none">
          <p className="px-4 pt-3 pb-1 dark:text-primary-100 text-primary-700 whitespace-nowrap">
            {t('select-a-network')}
          </p>
          <div className="p-1 space-y-1">
            {(() =>
              supportedNetworks.map((network, networkIdx) => (
                <Menu.Item key={networkIdx}>
                  {({ active }) => (
                    <button
                      onClick={() => switchChain(network.chainId)}
                      className={classNames(
                        'flex items-center px-3 py-2 rounded-md w-full justify-between text-primary-900 dark:text-primary-50',
                        {
                          'bg-primary-50 dark:bg-primary-700':
                            active || desiredNetwork === network.chainId,
                          'font-medium': desiredNetwork === network.chainId,
                        }
                      )}
                    >
                      <div className="flex space-x-2">
                        <network.iconComponent className="w-6 h-6" />
                        <span className="pr-4 whitespace-nowrap">
                          {t(network.chainName)}
                        </span>
                      </div>
                      <div
                        className={classNames('w-2 h-2 rounded-full', {
                          'bg-green-500': desiredNetwork === network.chainId,
                        })}
                      />
                    </button>
                  )}
                </Menu.Item>
              )))()}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default NetworkSelector;
