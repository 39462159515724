import { AppProps } from 'next/app';
import Head from 'next/head';

import { Web3Provider } from '@byont-ventures/react-dapp-framework';

import { networksConfig } from '../config';

import '../public/styles.css';
import { InvestmentProvider } from '../context/InvestmentContext';

import { appWithTranslation } from 'next-i18next';
import i18nConfig from '../next-i18next.config';

function MyApp({ Component, pageProps }: AppProps) {
  return (
    <>
      <Head>
        <title>DCIP - Investment Console</title>
      </Head>
      <main className="min-h-screen overflow-scroll bg-white">
        <Web3Provider networksConfig={networksConfig}>
          <InvestmentProvider>
            <Component {...pageProps} />
          </InvestmentProvider>
        </Web3Provider>
        <div id="headlessui-portal-root" className="absolute top-0 w-full">
          {/* It needs at least one child, so that HeadlessUI doesn't remove this portal root workaround
              ( https://github.com/tailwindlabs/headlessui/blob/main/packages/@headlessui-react/src/components/portal/portal.tsx#L84 ) */}
          <div />
        </div>
      </main>
    </>
  );
}

export default appWithTranslation(MyApp, i18nConfig);
