import React, { FunctionComponent } from 'react';
import classNames from 'classnames';

export const Notification: FunctionComponent<
  React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > & {
    notificationStyle?:
      | 'primary'
      | 'secondary'
      | 'light'
      | 'info'
      | 'warning'
      | 'danger'
      | 'success';
  }
> = ({ children, notificationStyle = 'info', ...props }) => {
  return (
    <div
      {...props}
      className={classNames(
        'px-4 py-4 rounded-lg flex w-full',
        {
          'bg-gray-100 text-gray-900 dark:bg-gray-500 dark:text-gray-100':
            notificationStyle === 'info',
          'bg-white text-gray-900  border-gray-100':
            notificationStyle === 'light',
          'bg-orange-100 text-orange-900 dark:bg-orange-500 dark:text-orange-100 ':
            notificationStyle === 'warning',
          'bg-red-100 text-red-900 dark:bg-red-500 dark:text-red-100':
            notificationStyle === 'danger',
          'bg-green-100 text-green-900 dark:bg-green-500 dark:text-green-100 ':
            notificationStyle === 'success',
          'bg-secondary-100 text-secondary-900 dark:bg-secondary-500 dark:text-secondary-100 ':
            notificationStyle === 'secondary',
          'bg-primary-100 text-primary-900 dark:bg-primary-500 dark:text-primary-100 ':
            notificationStyle === 'primary',
        },
        props.className
      )}
    >
      {children}
    </div>
  );
};

export default Notification;
