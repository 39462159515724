import React, { FunctionComponent } from 'react';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinnerThird } from '@fortawesome/pro-regular-svg-icons';

export const Button: FunctionComponent<
  React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > & {
    buttonStyle?:
      | 'primary'
      | 'secondary'
      | 'light'
      | 'info'
      | 'warning'
      | 'danger'
      | 'success';
    buttonSize?: 'sm' | 'md' | 'lg';
    loading?: boolean;
    shadow?: boolean;
  }
> = ({
  children,
  className,
  buttonStyle = 'light',
  buttonSize = 'md',
  disabled = false,
  loading = false,
  shadow = false,
  ...props
}) => {
  return (
    <button
      disabled={disabled}
      className={classNames(
        'rounded-lg flex border focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 items-center transition-all',
        {
          'px-6 py-3 text-lg': buttonSize === 'lg',
          'px-4 py-2': buttonSize === 'md',
          'px-2 py-1.5 text-sm': buttonSize === 'sm',
        },
        {
          'bg-gray-100 border-transparent hover:bg-gray-200 text-gray-900 focus-visible:ring-gray-500 active:bg-gray-300 dark:bg-primary-100 dark:hover:bg-primary-200 dark:text-primary-900 dark:focus-visible:ring-primary-500 dark:active:bg-primary-300':
            buttonStyle === 'info',
          'bg-white hover:bg-gray-100 hover:border-gray-50 text-primary-900 focus-visible:ring-primary-200 border-gray-200 active:bg-gray-200 active:border-gray-200 dark:bg-primary-800 dark:border-primary-700 dark:text-primary-50 dark:hover:bg-primary-700':
            buttonStyle === 'light',
          'bg-orange-100 border-transparent hover:bg-orange-200 text-orange-900 focus-visible:ring-orange-500 active:bg-orange-300':
            buttonStyle === 'warning',
          'bg-red-100 border-transparent hover:bg-red-200 text-red-900 focus-visible:ring-red-500 active:bg-red-300':
            buttonStyle === 'danger',
          'bg-green-100 border-transparent hover:bg-green-200 text-green-900 focus-visible:ring-green-500 active:bg-green-300':
            buttonStyle === 'success',
          'bg-secondary-100 border-transparent hover:bg-secondary-200 text-secondary-900 focus-visible:ring-secondary-500 active:bg-secondary-300':
            buttonStyle === 'secondary',
          'bg-primary-500 border-transparent hover:bg-primary-600 text-white focus-visible:ring-primary-500 active:bg-primary-700':
            buttonStyle === 'primary',
        },
        {
          'opacity-75 pointer-events-none': disabled,
        },
        {
          'shadow-md': shadow,
        },
        className
      )}
      {...props}
    >
      {(() =>
        loading ? (
          <FontAwesomeIcon icon={faSpinnerThird} className="animate-spin" />
        ) : (
          children
        ))()}
    </button>
  );
};

export default Button;
