import { Dialog } from '@headlessui/react';
import Modal from './Modal';
import Button from '../Button';
import Connector from '../../connectors/Connector';
import { useNetworkConfig } from '../../hooks/useWeb3';
import { NetworkSettings } from '../../types/networkTypes';
import { useTranslation } from 'react-i18next';
interface ConnectorContructor {
  new (allowedNetworks: NetworkSettings[]): Connector;
  isSupported: () => boolean;
}

export const SelectConnectorModal: React.FunctionComponent<{
  isOpen: boolean;
  onClose: () => void;
  onConnect: (connector: Connector) => void;
  connectors: {
    type: ConnectorContructor;
    label: string;
    icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
    disabled?: boolean;
  }[];
}> = ({ children, onConnect, connectors, ...props }) => {
  const { supportedNetworks } = useNetworkConfig();

  const { t } = useTranslation('react-dapp-framework');

  return (
    <Modal title={t('select-wallet')} {...props}>
      <div className="grid grid-cols-1 py-2 md:grid-cols-2 gap-x-4 gap-y-6">
        {(() =>
          connectors.map(
            ({ disabled, type, label, ...connectorConfig }, connectorIdx) => (
              <div key={connectorIdx} className="relative">
                <Button
                  buttonStyle="light"
                  disabled={disabled || !type.isSupported()}
                  onClick={() => onConnect(new type(supportedNetworks))}
                  className="justify-center w-full md:justify-start"
                >
                  <connectorConfig.icon className="w-10 h-10 p-1" />
                  <span className="mx-2">{label}</span>
                </Button>

                {(() => {
                  if (disabled) {
                    return (
                      <div className="absolute translate-x-2 -translate-y-1/2 top-0 right-0 text-xs bg-primary-100 px-1.5 py-0.5 rounded-full text-primary-700 shadow-md">
                        {t('coming-soon')}
                      </div>
                    );
                  } else if (!type.isSupported()) {
                    return (
                      <div className="absolute translate-x-2 -translate-y-1/2 top-0 right-0 text-xs bg-red-100 px-1.5 py-0.5 rounded-full text-red-700 shadow-md">
                        {t('not-supported')}
                      </div>
                    );
                  } else {
                    return null;
                  }
                })()}
              </div>
            )
          ))()}
      </div>
    </Modal>
  );
};

export default SelectConnectorModal;
