import { Dialog, Transition } from '@headlessui/react';
import classNames from 'classnames';
import { Fragment, FunctionComponent } from 'react';

export const Modal: FunctionComponent<
  React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > & {
    isOpen: boolean;
    title: string;
    onClose: () => void;
  }
> = ({ isOpen, onClose, className, title, children, ...props }) => {
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" onClose={onClose}>
        <div className="flex items-center justify-center h-full min-h-screen px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 z-40 bg-gray-900 bg-opacity-75" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div
              className={classNames(
                'relative z-50 inline-block p-6 text-left align-middle transition-all transform bg-white dark:bg-primary-900 shadow-xl rounded-2xl space-y-4 border dark:border-primary-800 border-gray-200 w-full md:w-auto',
                className
              )}
              {...props}
            >
              <Dialog.Title
                as="h3"
                className="pb-4 text-lg font-medium leading-6 border-b dark:border-b-primary-800 border-b-gray-200 dark:text-primary-50 text-primary-900"
              >
                {title}
              </Dialog.Title>
              {children}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default Modal;
