import Modal from './Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinnerThird } from '@fortawesome/pro-regular-svg-icons';
import Notification from '../Notification';
import Button from '../Button';
import { useTranslation } from 'react-i18next';

export const ConnectorConnectingModal: React.FunctionComponent<{
  isOpen: boolean;
  isConnecting: boolean;
  currentError: string | null;
  onClose: () => void;
  onTryAgain: () => void;
}> = ({ children, isConnecting, currentError, onTryAgain, ...props }) => {
  const { t } = useTranslation('react-dapp-framework');
  return (
    <Modal title={t('authorize-wallet')} className="max-w-lg" {...props}>
      <div className="dark:text-primary-50 text-primary-700">
        {t('dapp-requires-access-to-wallet')}
      </div>

      {(() =>
        isConnecting ? (
          <FontAwesomeIcon
            className="animate-spin dark:text-primary-50 text-primary-700"
            icon={faSpinnerThird}
          />
        ) : null)()}

      {(() =>
        null !== currentError
          ? [
              <Notification notificationStyle="danger">
                {currentError}
              </Notification>,
              <div className="flex justify-end">
                <div className="flex space-x-4">
                  <Button buttonStyle="light" onClick={() => props.onClose()}>
                    {t('dismiss')}
                  </Button>
                  <Button
                    buttonStyle="primary"
                    onClick={() => (onTryAgain ? onTryAgain() : null)}
                  >
                    {t('try-again')}
                  </Button>
                </div>
              </div>,
            ]
          : null)()}
    </Modal>
  );
};

export default ConnectorConnectingModal;
