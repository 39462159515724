import {
  CoreNetwork,
  useConnection,
} from '@byont-ventures/react-dapp-framework';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { dappConfig } from '../config';
import { useClient } from '../hooks/useGraphQL';
import { getInvestmentsQuery, getStrategiesQuery } from '../utils/queries';

export const enum StrategyType {
  TrailingStopLoss = 'TRAILING_STOPLOSS',
  TakeProfit = 'TAKE_PROFIT',
  LimitOrder = 'LIMIT_ORDER',
}

export const enum StrategyStatus {
  Activated = 'ACTIVATED',
  Deactived = 'DEACTIVATED',
  Executed = 'EXECUTED',
}

export interface Investment {
  id: string;
  tokenFrom: string;
  tokenTo: string;
  slippage: number;
  archived: boolean;
  chain: CoreNetwork;
  address: string;
}

export interface Strategy {
  id: string;
  amount: string;
  startingRate: string;
  executionRate: string | null;
  percentage: number | null;
  investmentId: string;
  type: StrategyType;
  status: StrategyStatus;
}

type InvestmentContextType = {
  investments: Investment[];
  investmentsLoading: boolean;

  strategies: Strategy[];
  strategiesLoading: boolean;

  selectedInvestment: Investment | null;
  setSelectedInvestment: (investment: Investment | null) => void;
};

export const InvestmentContext = createContext<InvestmentContextType>(
  {} as InvestmentContextType
);

export const useInvestmentContext = () => useContext(InvestmentContext);

export const InvestmentProvider: React.FunctionComponent = ({ children }) => {
  const client = useClient();
  const { desiredNetwork } = useConnection();

  const [strategies, setStrategies] = useState<Strategy[]>([]);
  const [strategiesLoading, setStrategiesLoading] = useState(false);

  const [investments, setInvestments] = useState<Investment[]>([]);
  const [investmentsLoading, setInvestmentsLoading] = useState(false);

  const [selectedInvestment, setSelectedInvestment] =
    useState<Investment | null>(null);

  const getInvestments = useCallback(async () => {
    setInvestmentsLoading(true);
    try {
      const response = await client.request<{
        investmentEntities: Investment[];
      }>(getInvestmentsQuery, {
        chain: dappConfig.graphSettings.chainFilter[desiredNetwork],
      });
      setInvestments(response.investmentEntities);
      setSelectedInvestment(response.investmentEntities[0]);
    } catch (ex) {
      // todo
    } finally {
      setInvestmentsLoading(false);
    }
  }, [client, desiredNetwork]);

  const getStrategies = useCallback(async () => {
    setStrategiesLoading(true);
    try {
      const response = await client.request<{ strategyEntities: Strategy[] }>(
        getStrategiesQuery,
        { investmentId: selectedInvestment.id }
      );
      setStrategies(response.strategyEntities);
    } catch (ex) {
      // todo
    } finally {
      setStrategiesLoading(false);
    }
  }, [client, selectedInvestment]);

  useEffect(() => {
    getInvestments();
  }, [getInvestments, desiredNetwork]);

  useEffect(() => {
    if (selectedInvestment === null) getInvestments();
  }, [selectedInvestment, getInvestments]);

  useEffect(() => {
    if (null !== selectedInvestment) getStrategies();
  }, [getStrategies, selectedInvestment]);

  return (
    <InvestmentContext.Provider
      value={{
        investments,
        investmentsLoading,
        strategies,
        strategiesLoading,
        selectedInvestment,
        setSelectedInvestment,
      }}
    >
      {children}
    </InvestmentContext.Provider>
  );
};
