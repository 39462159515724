import React from 'react';
import Token from './Token';

export const TokenPair: React.FunctionComponent<{
  tokenAddressA: string;
  tokenAddressB: string;
}> = ({ tokenAddressA, tokenAddressB }) => (
  <div className="relative mr-3">
    <Token address={tokenAddressA} />
    <Token address={tokenAddressB} className="absolute top-0 left-1/2" />
  </div>
);

export default TokenPair;
