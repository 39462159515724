import Jazzicon, { jsNumberForAddress } from 'react-jazzicon';
import {
  Fragment,
  FunctionComponent,
  useEffect,
  useMemo,
  useState,
} from 'react';
import Button from './Button';
import { truncateAddress } from '../utils/formatters';
import { useConnection, useNetworkConfig, useProvider } from '../hooks/useWeb3';
import { Popover, Transition } from '@headlessui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLink } from '@fortawesome/pro-regular-svg-icons';
import Connector from '../connectors/Connector';
import SelectConnectorModal from './modals/SelectConnectorModal';
import ConnectorConnectingModal from './modals/ConnectorConnectingModal';
import { MetaMaskConnector } from '../connectors/MetaMaskConnector';
import { WalletConnectConnector } from '../connectors/WalletConnectConnector';
import { MagicLinkConnector } from '../connectors/MagicLinkConnector';
import MetaMaskIcon from './icons/MetaMaskIcon';
import WalletConnectIcon from './icons/WalletConnectIcon';
import MagicLinkIcon from './icons/MagicLinkIcon';
import { useTranslation } from 'react-i18next';
import useDebounce from '../hooks/useDebounce';
// boo
export const AccountSelector: FunctionComponent = ({ children }) => {
  const { t } = useTranslation('react-dapp-framework');
  const {
    connect,
    isConnecting,
    isConnected,
    accountAddress,
    currentConnector,
    disconnect,
    desiredNetwork,
    selectedNetwork,
  } = useConnection();

  const provider = useProvider();
  const { supportedNetworks } = useNetworkConfig();

  const [ENSName, setENSName] = useState<string | null>(null);
  const [resolvingENS, setResolvingENS] = useState<boolean>(false);

  const [selectModalOpen, setSelectModalOpen] = useState(false);
  const [statusModalOpen, setStatusModalOpen] = useState(false);

  // There is a bug with overflow: hidden; not being removed from the HTML tag if set instantly.
  // https://github.com/tailwindlabs/headlessui/issues/1000#issuecomment-1001841999
  const debouncedStatusModalOpen = useDebounce<boolean>(statusModalOpen, 500);

  const [currentError, setCurrentError] = useState<string | null>(null);
  const [lastConnector, setLastConnector] =
    useState<Connector>(currentConnector);

  const blockExplorerUrl = useMemo(() => {
    const urls =
      supportedNetworks.find((network) => network.chainId === desiredNetwork)
        ?.blockExplorerUrls ?? [];
    if (urls.length) return urls[0];
    return '';
  }, [supportedNetworks, desiredNetwork]);

  useEffect(() => {
    const resolveENSName = async () => {
      if (!accountAddress) return;

      setResolvingENS(true);
      try {
        setENSName(await provider.lookupAddress(accountAddress));
      } catch (ex) {
        setENSName(null);
      } finally {
        setResolvingENS(false);
      }
    };

    resolveENSName();
  }, [accountAddress, desiredNetwork, selectedNetwork, provider]);

  const connectConnector = async (connector: Connector) => {
    setCurrentError(null);
    setSelectModalOpen(false);
    setStatusModalOpen(true);
    setLastConnector(connector);
    try {
      await connect(connector);
      setStatusModalOpen(false);
    } catch (ex: any) {
      setCurrentError(ex.message);
    }
  };

  if (!isConnected)
    return (
      <>
        <Button
          shadow={true}
          onClick={() => setSelectModalOpen(true)}
          className="capitalize"
        >
          {t('connect-wallet')}
        </Button>

        <SelectConnectorModal
          isOpen={selectModalOpen}
          onClose={() => setSelectModalOpen(false)}
          onConnect={(connector) => connectConnector(connector)}
          connectors={[
            {
              type: MetaMaskConnector,
              icon: MetaMaskIcon,
              label: 'MetaMask',
              disabled: false,
            },
            {
              type: WalletConnectConnector,
              icon: WalletConnectIcon,
              label: 'WalletConnect',
              disabled: false,
            },
            {
              type: MagicLinkConnector,
              icon: MagicLinkIcon,
              label: 'Magic Link',
              disabled: true,
            },
          ]}
        />

        <ConnectorConnectingModal
          isConnecting={isConnecting}
          currentError={currentError}
          isOpen={debouncedStatusModalOpen}
          onClose={() => setStatusModalOpen(false)}
          onTryAgain={() => connectConnector(lastConnector)}
        />
      </>
    );

  return (
    <Popover className="relative">
      <Popover.Button as="div">
        <Button loading={isConnecting || resolvingENS} shadow={true}>
          <Jazzicon seed={jsNumberForAddress(accountAddress || '')} />
          <span className="hidden ml-2 md:block">
            {ENSName || truncateAddress(accountAddress || '')}
          </span>
        </Button>
      </Popover.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Popover.Panel className="absolute right-0 transform translate-y-2 bg-white border border-gray-100 divide-gray-100 rounded-lg shadow-lg dark:divide-primary-800 dark:border-primary-700 dark:bg-primary-800 focus:outline-none">
          <div className="p-4 space-y-3">
            <h3 className="text-lg font-medium leading-6 text-primary-900 dark:text-primary-50">
              Your wallet
            </h3>
            <div className="flex items-center space-x-2">
              <Jazzicon
                diameter={32}
                seed={jsNumberForAddress(accountAddress || '')}
              />
              <div>
                {(() =>
                  blockExplorerUrl ? (
                    <a
                      href={`${blockExplorerUrl}/address/${accountAddress}`}
                      className="flex items-center space-x-1 text-primary-500 dark:text-primary-300 hover:text-primary-600 dark:hover:text-primary-400"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span>
                        {ENSName || truncateAddress(accountAddress || '')}
                      </span>
                      <FontAwesomeIcon icon={faExternalLink} size="sm" />
                    </a>
                  ) : (
                    <p>{ENSName || truncateAddress(accountAddress || '')}</p>
                  ))()}
                <p className="text-sm dark:text-primary-100 text-primary-700">
                  {currentConnector.getConnectorName()}
                </p>
              </div>
            </div>
            <Button
              buttonSize="sm"
              buttonStyle="info"
              onClick={() => disconnect()}
            >
              Disconnect
            </Button>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
};

export default AccountSelector;
