import { Decimal } from 'decimal.js';
import { BigNumberish } from 'ethers';
import {
  formatUnits as ethersFormatUnits,
  formatEther as ethersFormatEther,
} from 'ethers/lib/utils';

/**
 * Truncate an ethereum address into a 13 character string
 *
 * @param str The address to truncate
 * @returns A truncated address. The address will be 13 chars long. The initial 6 characters followed by `...` and the final 6 characters.
 */
export const truncateAddress = (str: string) =>
  `${str.slice(0, 6)}...${str.slice(str.length - 4)}`;

/**
 * Format an amount of wei into a decimal string representing the amount of units. E.g., 11000000000 gwei (unitName: 9) is 1.1 in decimals.
 *
 * @param value The value to format
 * @param unitName The amount of decimals the value has, for example Wei is 18 and gwei is 9. If not supplied will default to 18.
 * @param rounding How many decimals to round on, by default is 8, e.g., 1.000_000_000 will become 1.000_000_00
 * @returns
 */
export const formatUnits = (
  value: BigNumberish,
  unitName?: BigNumberish | undefined,
  rounding = 8
) =>
  new Decimal(ethersFormatUnits(value, unitName))
    .mul(10 ** rounding)
    .round()
    .div(10 ** rounding)
    .toString();

/**
 * Format an amount of wei into a decimal string representing the amount of ether.
 *
 * @param value The value to format
 * @param rounding How many decimals to round on, by default is 8, e.g., 1.000_000_000 will become 1.000_000_00
 * @returns
 */
export const formatEther = (value: BigNumberish, rounding = 8) =>
  new Decimal(ethersFormatEther(value))
    .mul(10 ** rounding)
    .round()
    .div(10 ** rounding)
    .toString();

/**
 * Formats a web3 Gateway to a web2 DNS address based on the IPFS gateway. E.g., `ipfs://Qmb8dPHULy6n8yJ4Z77GbvztwTCuMNRVbVq49iDHqFdo1X/1.json` will become `https://gateway.moralisipfs.com/ipfs/Qmb8dPHULy6n8yJ4Z77GbvztwTCuMNRVbVq49iDHqFdo1X/1.json`
 *
 * @param ipfs The string starting with ipfs://
 * @param gateway the gateway to use instead. Defaults to `https://gateway.moralisipfs.com/ipfs/`.
 * @returns
 */
export const formatIpfs = (
  ipfs: string,
  gateway = 'https://byont.mypinata.cloud/ipfs',
  cid = 'QmQDq7jjvgCjtJy1gFqd2Rg4nENCusxuJGMj677yfYSZuT'
) => {
  const filename = ipfs.split('/').pop();
  return `${gateway}/${cid}/${filename}`;
};
