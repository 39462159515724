import React from 'react';

export const PolygonIcon: React.FunctionComponent<
  React.SVGProps<SVGSVGElement>
> = ({ ...props }) => (
  <svg
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 119 119"
    {...props}
  >
    <circle cx="59.5" cy="59.5" r="59.5" fill="#8247E5" />
    <g clipPath="url(#clip0)">
      <path
        d="M78.375 47.182c-1.313-.752-3-.752-4.5 0l-10.5 6.206-7.125 3.95-10.313 6.205c-1.312.753-3 .753-4.5 0l-8.062-4.89c-1.313-.752-2.25-2.256-2.25-3.948V45.3c0-1.504.75-3.009 2.25-3.949l8.063-4.701c1.312-.753 3-.753 4.5 0L54 41.54c1.313.752 2.25 2.256 2.25 3.949v6.206l7.125-4.138v-6.394c0-1.504-.75-3.009-2.25-3.95l-15-8.838c-1.313-.752-3-.752-4.5 0L26.25 37.403c-1.5.752-2.25 2.257-2.25 3.761v17.678c0 1.504.75 3.009 2.25 3.95l15.188 8.838c1.312.752 3 .752 4.5 0l10.312-6.018 7.125-4.137 10.313-6.018c1.312-.752 3-.752 4.5 0l8.062 4.701c1.313.752 2.25 2.257 2.25 3.95v9.402c0 1.505-.75 3.01-2.25 3.95l-7.875 4.701c-1.313.752-3 .752-4.5 0l-8.063-4.701c-1.312-.752-2.25-2.257-2.25-3.95v-6.017l-7.124 4.137v6.206c0 1.504.75 3.009 2.25 3.95l15.187 8.838c1.313.752 3 .752 4.5 0l15.188-8.839c1.312-.752 2.25-2.257 2.25-3.95V59.97c0-1.504-.75-3.009-2.25-3.95l-15.188-8.838z"
        fill="#fff"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <path fill="#fff" transform="translate(24 28)" d="M0 0h72v63H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default PolygonIcon;
