import { FunctionComponent } from 'react';
import ByontIcon from './icons/ByontIcon';

export const AppFooter: FunctionComponent = ({ children }) => {
  return (
    <div className="container flex justify-end py-6 text-sm">
      {children}
      <a
        className="flex items-center space-x-2"
        href="https://byont.io"
        target="_blank"
        rel="noreferrer noopener"
      >
        <span className="text-sm font-medium text-primary-700">Powered by</span>
        <ByontIcon className="h-4 fill-primary-700" />
      </a>
    </div>
  );
};
//boogey

export default AppFooter;
