import React from 'react';

export const ByontIcon: React.FunctionComponent<
  React.SVGProps<SVGSVGElement>
> = ({ ...props }) => (
  <svg
    viewBox="0 0 360 144"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g
      id="Brand-/-Logo-/-Slim-/-black"
      stroke="none"
      strokeWidth="1"
      fill="currentFill"
      fillRule="evenodd"
    >
      <path
        d="M97.270195,50 L109.303485,84.201 L127.35376,50 L147.409471,50 L117.198485,106.638 L117.325905,107 L117.005485,107 L97.270195,144 L77.7424847,144 L97.270195,107 L77.2144847,50 L97.270195,50 Z M20.0557103,0 L20.0557103,85 C20.0557103,93.2842712 26.7901458,100 35.097493,100 C43.4048402,100 50.1392758,93.2842712 50.1392758,85 C50.1392758,76.7157288 43.4048402,70 35.097493,70 L35,70.001 L35,50.001 L35.097493,50 C54.2874651,50 69.8803454,65.3582001 70.190284,84.421211 L70.1949861,85 C70.1949861,104.329966 54.4813032,120 35.097493,120 C15.907521,120 0.31464067,104.6418 0.00470206131,85.578789 L5.68434189e-14,85 L6.39488462e-14,0 L20.0557103,0 Z M184.512535,50 C203.896345,50 219.610028,65.6700338 219.610028,85 C219.610028,104.329966 203.896345,120 184.512535,120 C165.128725,120 149.415042,104.329966 149.415042,85 C149.415042,81.0882839 150.058545,77.3264485 151.245743,73.8140241 L170.277808,80.1400421 C169.754664,81.6641981 169.470752,83.2990356 169.470752,85 C169.470752,93.2842712 176.205188,100 184.512535,100 C192.819882,100 199.554318,93.2842712 199.554318,85 C199.554318,76.7157288 192.819882,70 184.512535,70 C182.51303,70 180.604648,70.389057 178.859359,71.0954 L172.509126,52.100389 C176.254018,50.7413235 180.296462,50 184.512535,50 Z M266.740947,50 C285.930919,50 301.523799,65.3582001 301.833738,84.421211 L301.83844,85 L301.83844,120 L281.78273,120 L281.78273,85 C281.78273,76.7157288 275.048294,70 266.740947,70 C258.538756,70 251.869908,76.5467864 251.702391,84.6861718 L251.699164,85 L251.699164,120 L231.643454,120 L231.643454,85 L231.648156,84.421211 C231.958095,65.3582001 247.550975,50 266.740947,50 Z M344.958217,25 L344.957724,50 L360,50 L360,70 L344.957724,70 L344.958217,85 C344.958217,93.2842712 351.692653,100 360,100 L360,120 L359.419599,119.995311 C340.494649,119.689324 325.210898,104.446134 324.907116,85.5730122 L324.902507,85 L324.901724,70 L309.860724,70 L309.860724,50 L324.901724,50 L324.902507,25 L344.958217,25 Z"
        id="Combined-Shape"
      ></path>
    </g>
  </svg>
);

export default ByontIcon;
